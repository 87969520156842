<template>
  <div>
    <h4>Robes</h4>
    
    <data-table
      :data-source="robes"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript: void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#list_type_robe"
        >
          <i class="icofont icofont-list" />
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-robe"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>



    <modal
      title="Types de robes"
      id="list_type_robe"
      size="x-large"
    >
      <form
        @submit.prevent="addingType"
        style="padding-bottom: 10px; margin-bottom: 10px; border-bottom: 1px solid rgba(0, 0, 0, 0.15);"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="libelle-type">Libelle</label>
          <input
            type="text"
            class="form-control"
            name="libelle-type"
            v-model="typeLibelle"
          >
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            {{ editMode? 'Enregistrer': 'Ajouter' }}
          </button>
        </div>
      </form>
      <data-table
        :data-source="typeRobes"
        :headers="typeRobeHeaders"
        id="type-robe"
        table-class="table-striped table-hover"
      /> 
    </modal>





    <modal
      title="Ajout de robe"
      id="add-robe"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="addingRobe"
      >
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            type="text"
            id="add-libelle"
            name="add-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="add-type">Type de robe</label>
          <select
            v-model="type"
            class="form-control"
            name="add-type"
            v-select="{placeholder: 'Selectionnez le type de robe'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typeRobes"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="add-espece">Espèce</label>
          <select
            v-model="espece"
            class="form-control"
            name="add-espece"
            v-select="{placeholder: 'Selectionnez l\'espèce'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in especes"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
      <template slot="footer">
        <div />
      </template>
    </modal>
    <modal
      title="Modification de l'espèce"
      id="update-espece"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editingRobe"
      >
        <div class="form-group">
          <label for="edit-libelle">Libelle</label>
          <input
            type="text"
            id="edit-libelle"
            name="edit-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="edit-type">Type de robe</label>
          <select
            v-model="type"
            class="form-control"
            name="edit-type"
            v-select="{placeholder: 'Selectionnez le type de robe'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typeRobes"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="edit-espece">Espèce</label>
          <select
            v-model="espece"
            class="form-control"
            name="edit-espece"
            v-select="{placeholder: 'Selectionnez l\'espèce'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in especes"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
      <template slot="footer">
        <div />
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import { Select } from '../../../directive/select'
import {ADD_ROBE, ADD_TYPE_ROBE, UPDATE_ROBE, UPDATE_TYPE_ROBE, } from '../../../graphql/identification'
import DataTable from '../../../components/dataTable/local.vue'
import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
import Modal from '../../../components/modal.vue'
import { MODE_ADD, MODE_EDIT } from '../../../constants/app'
const Espece = ()=> import('../../../components/admin/identification/especeInline.vue')
const TypeRobe = () => import('../../../components/admin/identification/typeRobeInline.vue')
const Actions = () => import('../../../components/admin/identification/robeAction.vue')
const TypeActions = () => import('../../../components/admin/identification/typeRobeAction.vue')
export default {
    components: { DataTable, Modal},
    directives: {Select},
    data(){
        return {
            typeLibelle: null,
            libelle: null,
            espece: null,
            type: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        selectedObject: {
            handler(){
                if(this.selectedObject !== null && this.selectedObject.__typename === 'Robe') {
                    this.libelle = this.selectedObject.libelle
                    this.code = this.selectedRobe.code
                }else if(this.selectedObject !== null && this.selectedObject.__typename === 'TypeRobe'){
                    this.typeLibelle = this.selectedObject.libelle
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedRobe: 'SET_SELECTED_OBJECT',
            setMode: 'SET_MODE'
        }),

        addingType(){
            let data = {
                libelle: this.typeLibelle
            }

            if(this.editMode) {
                this.$apollo.mutate({
                    mutation: UPDATE_TYPE_ROBE,
                    variables: {
                        "type": {
                            ...data
                        },
                        "uid": this.selectedObject.uid
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Type robe updted successfully`)
                    }
                }).then(() => {
                        this.typeLibelle = null
                        this.setMode(MODE_ADD)
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }else{
                this.$apollo.mutate({
                    mutation: ADD_TYPE_ROBE,
                    variables: {
                        "type": {
                            ...data
                        }
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Type robe add successfully`)
                    }
                }).then(() => {
                        this.typeLibelle = null
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
            
            
        },
        initForm(){
            this.setSelectedRobe(null)
            this.libelle = null
            this.espece = null
            this.type = null
        },
        addingRobe(){
            let data = {
                libelle: this.libelle,
                espece: this.espece,
                type: this.type
            }
            this.$apollo.mutate({
                mutation: ADD_ROBE,
                variables: {
                    "robe": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Robe add successfully on uid ${d.addRobe}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editingRobe(){
            let data = {
                libelle: this.libelle,
                espece: this.espece,
                type: this.type
            }
            this.$apollo.mutate({
                mutation: UPDATE_ROBE,
                variables: {
                    "espece": {
                        ...data
                    }, 
                    "uid": this.selectedObject.uid
                },
                update: () => {
                    console.log(`Robe  ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            robes: 'identification/robes',
            typeRobes: 'identification/typeRobes',
            especes: 'identification/especes',
            selectedObject: 'selectedObject',
            mode: 'mode'
        }),
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Type Robe', name: 'espece', type: COMPONENT_TYPE, component: TypeRobe},
                {label: 'Espece', name: 'espece', type: COMPONENT_TYPE, component: Espece},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        },
        typeRobeHeaders(){
            return [
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE, sortable: true,  style: {width: "70%"}},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: TypeActions, style: {width: "20%"}}
            ]
        },
        editMode(){
            return this.mode === MODE_EDIT
        },
        addMode(){
            return this.mode === MODE_ADD
        }
    }
}
</script>

<style>

</style>